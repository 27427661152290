import React from "react";
import ReactDOM from "react-dom";
import Spinner from "react-bootstrap/Spinner";

import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

import "./i18n";

import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <React.Fragment>
    <React.Suspense
      fallback={
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      }
    >
      <App />
    </React.Suspense>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
