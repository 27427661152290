import React from "react";
import { Redirect } from "react-router-dom";

import Clients from "./Clients";

import Can from "../../../../components/Can";
import { AuthContext } from "../../../../context/Auth";

import * as ROUTE from "../../../../constants/route";

function ClientList(props) {
  const { currentUser } = React.useContext(AuthContext);

  return (
    <Can
      role={currentUser.role}
      permission="list:clients"
      no={<Redirect to={ROUTE.HOME} />}
    >
      <Clients />
    </Can>
  );
}

export default ClientList;
