import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import firebaseConfig from "./config";
import storage from "../storage";
import { getDefaultLocale } from "react-datepicker";
import { ref } from "@hapi/joi";

// Initialize Firebase

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.firestore();
  }

  async register(name, email, password) {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );

    return await user.updateProfile({
      displayName: name,
    });
  }

  async getToken() {
    return await this.auth.currentUser.getIdToken();
  }

  async login(email, password) {
    return await this.auth.signInWithEmailAndPassword(email, password);
  }

  async logout() {
    await this.auth.signOut();
    storage.remove("refreshToken");
  }

  async resetPassword(email) {
    await this.auth.sendPasswordResetEmail(email);
  }

  clients() {
    return this.db.collection("clients");
  }

  client(uid) {
    return this.db.collection(`clients`).doc(uid);
  }

  /**
   * 指定クライアントの工数集計データをドキュメントから取り出す
   * @param {*} uid 
   * @returns 
   */
  async clientActualWorkHistories(uid){
    let client = await this.db.collection("clients").doc(uid).get();
    if(client.exists)
    {
      //console.debug(uid+' is EXISTS');
      return client.get('actualWorkHistories');
    }
    else
    {
      return false;
    }

  }


  clientWorkHistories(clientId) {
    return this.db
      .collection("workHistories")
      .where("clientId", "==", clientId);
  }

  workHistories() {
    return this.db.collection("workHistories");
  }

  logs() {
    return this.db.collection("logs");
  }
}

const firebase = new Firebase();

export default firebase;
