/**
 * コンポーネントパーツ: ActualManhourHistories
 */
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import { exportXLSX } from "../../../../../utils/helpers";


/**
 * コンポーネントパーツ: 実績工数履歴
 * 
 * @param {*} param0 
 * @returns 
 */
function ActualManHourHistories({
    histories,
    pendingWorkHistories,
    lastSync,
    actualHours,
    presentedManHours,
    clientCode,
    buffer2=100,
  }) {

  //console.debug(histories.length);

  const { t } = useTranslation();
  const [
    isDownloadingActualManHourCSV,
    setIsDownloadingActualManHourCSV,
  ] = React.useState(false);
  const [actualManHourHistories, setActualManHourHistories] = React.useState(histories);


  // 副作用フック
  React.useEffect(() => {
    setActualManHourHistories(histories);
  }, [histories]);

  // handle:Excel,CSV出力
  const handleExportActualManHourHistory = async (e) => {
    try {
      if (!histories || !histories.length) {
        return;
      }

      setIsDownloadingActualManHourCSV(true);

      const filename = `${clientCode}_MH_histories_${moment().format(
        "YYYY_MM_DD_HH_MM_SS"
      )}`;

      exportXLSX(
        histories.map((d) => ({
          "契約期間内実績工数（集計）": d.yearMonth,
          "集計工数(h)": d.manHour,
          "提示工数（ｘ提示用倍率）": d.presentedManHour,
        })),
        filename
      );
    } catch (ex) {
      toast.error(t("The actual man-hour csv download failed."));
    } finally {
      setIsDownloadingActualManHourCSV(false);
    }
  };

  return (
    <>
      <h3 className="my-4 clients--basic-info__header d-flex justify-content-between align-items-center">
        <span>{t("actualManHourHistory")}</span>
        <Button
          type="button"
          className="btn btn-primary"
          onClick={handleExportActualManHourHistory}
          style={{ minWidth: "100px" }}
          disabled={isDownloadingActualManHourCSV}
        >
          {isDownloadingActualManHourCSV ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "EXCEL"
          )}
        </Button>
      </h3>
      <Table
        responsive
        className="table table--clients table-bordered border border-primary"
      >
        <tbody>
          <tr className="border border-primary">
            <td className="w-25 align-middle bg-primary text-light text-center p-1">
              {t("aggregationDate")}
            </td>
            <td>
              <Form.Control
                readOnly
                value={lastSync}
                className={`border-0 bg-light shadow-none`}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      <Table
        responsive 
        className="table--clients-history table-bordered border wf-border-primary"
      >
        <thead>
          <tr>
            <th rowSpan={2} className="text-center wf-bg-primary text-light">
              {t("totalActualManhourWithinContract")}
            </th>
            <th className="wf-bg-primary text-light">
              {t("集計工数(h)")}
            </th>
            <th className="wf-bg-primary text-light">
              {t("presentationTimeXBuffer")}
            </th>
          </tr>
          <tr>
            <th>{actualHours || 0} h</th>
            <th>{parseFloat(Number(actualHours*(buffer2/100)).toFixed(2)) || 0} h</th>
          </tr>
        </thead>
        <tbody>
          {pendingWorkHistories && (
            <tr>
              <td colSpan={3} className="text-center">
                <Spinner animation="border" variant="primary" />
                <p className="text-muted" style={{ fontSize: "0.8em" }}>
                  {t("Generating work histories from backlog")}
                </p>
              </td>
            </tr>
          )}
          {actualManHourHistories.map((h, index) => (
            <tr className="border border-primary" key={index}>
              <td className="w-25 align-middle wf-bg-secondary text-dark text-center p-1 font-weight-bold">
                {h.yearMonth}
              </td>
              <td>{h.manHour}</td>
              <td>{parseFloat(Number(h.manHour*(buffer2/100)).toFixed(2))}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default ActualManHourHistories;
