class Storage {
  get(key) {
    const value = localStorage.getItem(key);

    if (!value) {
      return null;
    }

    return value.indexOf("{") === 0 || value.indexOf("[") === 0
      ? JSON.parse(value)
      : value;
  }

  save(key, value) {
    const data = typeof value === "object" ? JSON.stringify(value) : value;

    localStorage.setItem(key, data);
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

const storage = new Storage();

export default storage;
