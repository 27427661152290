import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import Can from "../../../../components/Can";

import { AuthContext } from "../../../../context/Auth";

import ClientForm from "../../../../components/ClientForm";
import * as ROUTE from "../../../../constants/route";

export default function (props) {
  const history = useHistory();
  const { currentUser } = React.useContext(AuthContext);

  return (
    <Can
      role={currentUser.role}
      permission="create:clients"
      no={<Redirect to={ROUTE.HOME} />}
    >
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={(e) => {
            history.push("/");
          }}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>クライアント登録</Breadcrumb.Item>
      </Breadcrumb>
      <ClientForm action="register" />
    </Can>
  );
}
