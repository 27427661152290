import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from "../context/Auth";

import * as ROUTE from "../constants/route";

const PrivateRoute = ({ component: Component, role, ...rest }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        currentUser ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={ROUTE.LOGIN} />
        )
      }
    />
  );
};

export default PrivateRoute;
