/**
 * コンポーネントパーツ: BasicInformations / 顧客基本情報
 */
import React from "react";
import { useFormContext } from "react-hook-form";
import { pick, omit, toNumber } from "lodash";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import firebase from "../../services/firebase";
import Http from "../../services/http";
import { GCP_FUNCTIONS_CREATE_AUTH_CLIENT } from "../../config";

import FormErrors from "../FormErrors";
import FormHeading from "./FormHeading";
import TableInputControlRow from "./TableInputControlRow";

const basicInfoKey = ["code", "name", "email", "password", "confirmPassword"];

/**
 * firebase Authentication への追加
 * @param {*} values 
 */
async function registerClientAuth(values) {
  // For create user pass only relevant fields
  const payload = pick(values, basicInfoKey);
  const { status } = await Http.post(GCP_FUNCTIONS_CREATE_AUTH_CLIENT, payload);

  if (status !== 200) {
    throw new Error("Failed to register client");
  }
}


/**
 * firebase Authentication での更新
 * @param {*} values 
 */
async function updateClientAuth(values) {
  // For update user pass only relevant fields
  const payload = pick(values, basicInfoKey);
  const { status } = await Http.put(
    `${GCP_FUNCTIONS_CREATE_AUTH_CLIENT}/${values.code}`,
    payload
  );

  if (status !== 200) {
    throw new Error("Failed to update client");
  }
}


/**
 * firestoreへの追加格納
 * @param {*} payload 
 * @returns 
 */
async function addClientToFirestore(payload) {
  payload = omit(payload, ["password", "confirmPassword"]);

  // create initial client info
  // this includes contract context fields
  const result = await firebase.clients().add({
    ...payload,
    buffer: toNumber(payload.buffer),
    contractManHour: toNumber(payload.contractManHour),
  }); // we don't need password and confirmPassword to store in our firestore

  return Promise.resolve(result);
}


/**
 * firestoreへの更新格納
 * @param {*} cid 
 * @param {*} payload 
 * @returns 
 */
async function updateClientToFirestore(cid, payload) {
  const result = await firebase
    .client(cid)
    .update(omit(payload, ["password", "confirmPassword"])); // we don't need to password and confirmPassword to store in our firestore

  return Promise.resolve(result);
}



/**
 * コンポーネント: BasicInformation
 * @param {*} props 
 * @returns 
 */
function BasicInformation(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const {
    register,
    errors,
    triggerValidation,
    getValues,
    action,
    view,
    watch,
    setValue,
  } = useFormContext();
  const [edit, setEdit] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  // 編集キャンセル時 復帰文字列を確保するためのやつ
  const [clientDefault, ] = React.useState(getValues());
  //console.debug(client_default);

  
  /**
   * handler:新規登録時
   * @param {*} e 
   * @returns 
   */
  const handleRegister = async (e) => {
    e.preventDefault();

    // validate basic info form key
    const isValid = await triggerValidation([
      "code",
      "name",
      "email",
      "password",
      "confirmPassword",
      "contractPeriodStart",
      "contractPeriodEnd",
      "contractManHour",
      "buffer",
      "buffer2",
    ]);

    if (!isValid) {
      return;
    }

    setSaving(true);

    let alreadyExists = false;
    const payload = getValues();

    // support case insensitive function for client code
    const clients = await firebase.clients().get();

    if (clients.size > 0) {
      const clientsData = clients.docs
        .map((c) => ({ ...c.data(), id: c.id }))
        .filter(
          ({ code }) => code.toLowerCase() === payload.code.toLowerCase()
        );

      alreadyExists = !!clientsData.length;
    }

    if (alreadyExists) {
      setSaving(false);
      toast.error(
        t(
          "Unable to register because the client code duplicates the existing client. Please register with a different character string."
        )
      );
      return;
    }

    try {
      await registerClientAuth(payload);
    } catch (ex) {
      setSaving(false);
      toast.error(
        t(
          "Cannot register because the user name is the same as the existing client. Please register with a different character string."
        )
      );
      return;
    }

    try {
      const result = await addClientToFirestore(payload);

      toast.success(t("Client addition is complete."));

      setSaving(false);

      history.push(`/clients/${result.id}`);
    } catch (exceptionError) {
      setSaving(false);
      toast.error(exceptionError.message || "Something went wrong!");
    }
  };


  /**
   * handler:更新登録時
   * @param {*} e 
   * @returns 
   */
  const handleUpdate = async (e) => {
    e.preventDefault();
    // validate basic info form key
    // exclude password and confirmPassword during update validation
    const values = getValues();
    const isValid = await triggerValidation(basicInfoKey);

    // errors already have values comming from other component
    const basicInfoErrors = pick(errors, basicInfoKey);

    if (!isValid && Object.keys(basicInfoErrors).length) {
      return; 
    }

    try {
      setSaving(true);

      if (values.password && values.confirmPassword) {
        await updateClientAuth(values);
      }

      await updateClientToFirestore(params.id, getValues());

      toast.success(t("The client update is complete."));

      setSaving(false);
      setEdit(false);

      // firestore更新に使ったデータでstateを書き換える。
      //   顧客基本情報:
      clientDefault.name  = values.name;
      clientDefault.code  = values.code;
      clientDefault.email = values.email;
      setValue("code",  clientDefault.code);
      setValue("name",  clientDefault.name);
      setValue("email", clientDefault.email);

      //    契約基本情報


    } catch (exceptionError) {
      setSaving(false);
      toast.error(exceptionError.message || "Something went wrong!");
    }
  };


  const isForUpdateClient = action === "update";
  const isNotUpdateOrRegister = (!edit && !!isForUpdateClient) || view; // isForUpdateClient = register/add client
  const handlePasswordKeyPress = (e) => {
    const pattern = /[\u3000-\u303F]|[\u2190-\u2195]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;

    if (pattern.test(e.target.value)) {
      e.preventDefault();
      return false;
    }
  };

  /**
   * レンダーアクション
   * @returns 
   */
  function renderAction() {
    if (view) {
      return;
    }

    if (isForUpdateClient) {
      return (
          // 編集とキャンセルボタン + 更新実行ボタン の設定
        <>
          <Button className="w-25" onClick={() => setEdit(!edit)}>
            {edit ? t("cancel") : t("edit")}
          </Button>
          <span className="mx-1"></span>
          <Button
            type="button"
            className="w-25"
            disabled={!edit || saving}
            onClick={handleUpdate}
          >
            {saving ? (
              <Spinner size="sm" animation="border" variant="light" />
            ) : (
              t("update")
            )}
          </Button>
        </>
      );
    }
    return (
      <Button
        type="button"
        className="w-25"
        disabled={edit || saving}
        onClick={handleRegister}
      >
        {saving ? (
          <Spinner size="sm" animation="border" variant="light" />
        ) : (
          t("register")
        )}
      </Button>
    );
  }

  // 副作用フック
  React.useEffect(() => {
    
    if ((!edit && !!isForUpdateClient) || view) {
      setValue("password", "*********");
      setValue("confirmPassword", "*********");

      // 編集キャンセル時 復帰文字列
      if(!edit)
      {
        //  顧客基本情報
        setValue("code",  clientDefault.code);
        setValue("name",  clientDefault.name);
        setValue("email", clientDefault.email);
      }
    } else {
      setValue("password", "");
      setValue("confirmPassword", "");
    }
  }, [edit, isForUpdateClient, setValue, view]);



  return (
    <>
      <FormHeading title={t("basicInformation")}>{renderAction()}</FormHeading>

      {/* DISPLAY ERRORS FOR BASIC INFO FIELD */}
      <FormErrors errors={pick(errors, basicInfoKey)} />

      <Table
        responsive
        className="table table--clients table-bordered border border-primary"
      >
        <tbody>
          <TableInputControlRow
            label={t("clientCode")}
            controlInput={
              <Form.Control
                name="code"
                disabled={isNotUpdateOrRegister}
                ref={register}
                autoComplete="off"
                placeholder={t("Enter client code")}
                className="border-0 bg-light shadow-none"
              />
            }
          />
          <TableInputControlRow
            label={t("clientName")}
            controlInput={
              <Form.Control
                name="name"
                disabled={isNotUpdateOrRegister}
                ref={register}
                placeholder={t("Please enter the client name")}
                className="border-0 bg-light shadow-none"
                autoComplete="off"
              />
            }
          />
          <TableInputControlRow
            label={t("loginID")}
            controlInput={
              <Form.Control
                type="email"
                name="email"
                disabled={isNotUpdateOrRegister}
                ref={register}
                placeholder={t("Enter Client Login ID")}
                className={`border-0 bg-light shadow-none`}
                autoComplete="off"
              />
            }
          />
          <TableInputControlRow
            label={t("password")}
            controlInput={
              <Form.Control
                type="password"
                name="password"
                disabled={isNotUpdateOrRegister}
                ref={register({
                  validate: (value) => value === watch("confirmPassword"),
                })}
                placeholder={t("Enter Client Password")}
                className={`border-0 bg-light shadow-none`}
                autoComplete="off"
                onKeyPress={handlePasswordKeyPress}
              />
            }
          />
          <TableInputControlRow
            label={t("confirmPassword")}
            controlInput={
              <Form.Control
                type="password"
                name="confirmPassword"
                disabled={isNotUpdateOrRegister}
                ref={register({
                  validate: (value) => value === watch("password"),
                })}
                placeholder={t("Enter Client Confirm Password")}
                className={`border-0 bg-light shadow-none`}
                autoComplete="off"
              />
            }
          />
        </tbody>
      </Table>
    </>
  );
}

export default BasicInformation;
