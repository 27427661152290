import React from "react";
import firebase from "../services/firebase";
import { useHistory } from "react-router-dom";

import * as ROUTE from "../constants/route";
import LINK from "../constants/links";

export const LayoutContext = React.createContext();

const LayoutProvider = ({ children }) => {
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  const [isMDScreen, setIsMDScreen] = React.useState(false);

  const adjustScreen = () => {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

    if (windowWidth <= 768) {
      setIsMDScreen(false);
    } else {
      setIsMDScreen(true);
    }
  };

  const handleInqueryLink = (e) => {
    const otherWindow = window.open();
    otherWindow.opener = null;
    otherWindow.location = LINK.CONTACT_US;
  };

  const handleLogout = async () => {
    try {
      await firebase.logout();
      setModalShow(false);
      history.push(ROUTE.LOGIN);
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    adjustScreen();

    window.addEventListener("resize", adjustScreen);

    return () => window.removeEventListener("resize", () => {});
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        modalShow,
        setModalShow,
        isMDScreen,
        setIsMDScreen,
        handleInqueryLink,
        handleLogout,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
