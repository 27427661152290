import React from "react";
import { Link, useHistory } from "react-router-dom";

import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { isUserAdmin } from "../utils/helpers";
import Logo from "../assets/logo.png";

import * as ROUTE from "../constants/route";
import { AuthContext } from "../context/Auth";
import { LayoutContext } from "../context/Layout";

function Sidenav() {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentUser } = React.useContext(AuthContext);
  const { setModalShow, handleInqueryLink } = React.useContext(LayoutContext);

  return (
    <nav
      className="app-sidebar justify-content-sm-between justify-content-md-start d-none d-md-flex"
      id="sidebar"
    >
      <Link
        to={ROUTE.HOME}
        className="text-center py-md-4 text-uppercase mt-md-5 logo-text"
      >
        <img
          src={Logo}
          alt="WillFind"
          id="logo"
          className="img-fluid"
          style={{ maxWidth: "150px" }}
        />
      </Link>




      <div className="d-md-flex flex-column">
        {isUserAdmin(currentUser) && (
          <>
            <Button
              type="button"
              variant="primary"
              className="mx-4 my-1 btn btn-primary"
              onClick={() => history.push(ROUTE.CLIENTS)}
            >
              {t("clientList")}
            </Button>
            
            <Button
              type="button"
              variant="primary"
              className="mx-4 my-1 btn btn-primary "
              onClick={() => history.push(ROUTE.CLIENTS_REGISTER)}
            >
              {t("newClient")}
            </Button>
            <Button
              type="button"
              variant="primary"
              className="mx-4 my-1 btn btn-primary "
              onClick={() => history.push(ROUTE.LOGS)}
            >
              {t("Batch error log")}
            </Button>
          </>
        )}

        <Button
          type="button"
          variant="primary"
          className="mx-4 my-1 btn btn-primary "
          onClick={handleInqueryLink}
        >
          {t("inquiry")}
        </Button>
        <></>
        {currentUser && (
          <Button
            type="button"
            variant="primary"
            className="mx-4 my-1 btn btn-primary logout"
            onClick={() => setModalShow(true)}
          >
            {t("logout")}
          </Button>
        )}
        </div>

    </nav>
  );
}

export default Sidenav;
