// HTTP LIBRARY CONFIG
export const HTTP_TIMEOUT = process.env.REACT_APP_HTTP_TIMEOUT || 100000;

// PAGINATION
export const PAGINATION_PER_PAGE =
  process.env.REACT_APP_PAGINATION_PER_PAGE || 10;

// FIREBASE CONFIG
export const FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY || null;
export const FIREBASE_DOMAIN = process.env.REACT_APP_FIREBASE_DOMAIN || null;

export const FIREBASE_DATABASE =
  process.env.REACT_APP_FIREBASE_DATABASE || null;
export const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID || null;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || null;
export const FIREBASE_SENDER_ID =
  process.env.REACT_APP_FIREBASE_SENDER_ID || null;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID || null;

export const LANGUAGE = process.env.LANGUAGE || "jp"; // JAPANESE

// GOOGLE CLOUD SERVICE FUNCTIONS
export const GCP_FUNCTIONS_BASE_URI =
  process.env.REACT_APP_GCP_FUNCTIONS_BASE_URI ||
  "https://asia-northeast1-willfind.cloudfunctions.net";
export const GCP_FUNCTIONS_CREATE_AUTH_CLIENT = `${GCP_FUNCTIONS_BASE_URI}/api/clients`;
export const GCP_FUNCTIONS_EXPORT = `${GCP_FUNCTIONS_BASE_URI}/api/export`;

export const TOAST_AUTO_CLOSE_TIME =
  process.env.REACT_APP_TOAST_AUTO_CLOSE_TIME || 1500;
