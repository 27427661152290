import React from "react";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Can from "../../../../components/Can";
import { AuthContext } from "../../../../context/Auth";
import firebase from "../../../../services/firebase";
import ClientForm from "../../../../components/ClientForm";
import {
  getWorkHistoriesDocuments,
  getDate,
  getTotalPresentedHour,
  getTotalActualHour,
  getCurrentActualManHourHistories,
} from "../../../../utils/helpers";
import * as ROUTE from "../../../../constants/route";
import FORMAT from "../../../../constants/format";
import WorkHistories from "./components/WorkHistories";
import ActualManHourHistories from "./components/ActualManhourHistories";
import { ClientFormProvider } from "../../../../context/ClientForm";

export default function (props) {
  const params = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = React.useContext(AuthContext);
  const [pending, setPending] = React.useState(true);
  const [lastSync, setLastSync] = React.useState("");
  const [pendingWorkHistories, setPendingWorkHistories] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [workHistories, setWorkHistories] = React.useState([]);
  const [actualManHourHistories, setActualManHourHistories] = React.useState([]);
  const [totalActualHour, setTotalActualHour] = React.useState(0);
  const [totalPresentedHour, setTotalPresentedHour] = React.useState(0);

  React.useEffect(() => {
    let unsubscribe = () => {};

    const initializeClientData = async (clientWorkHistories) => {
      const clientDoc = await firebase.client(params.id).get();
      const clientData = clientDoc.data();

      if (!clientData) {
        return;
      }

      console.debug('[[[[DEBUG]]]]__________________________________');
      console.debug(JSON.stringify(clientData.contractPeriodStart));
      console.debug(moment(clientData.contractPeriodStart).startOf('month'));
      console.debug('[[[[DEBUG]]]]________');
      console.debug(moment(clientData.contractPeriodEnd).endOf("month").subtract(1, "day"));

      const client = {
        ...clientData,
        contractPeriodStart: getDate(clientData.contractPeriodStart),
        contractPeriodEnd: getDate(clientData.contractPeriodEnd),
      };

      setValues(client);

      setLastSync(getDate(client.lastSync, FORMAT.DEFAULT_DATETIME_FORMAT));

      const workHistories = getWorkHistoriesDocuments(clientWorkHistories);

      if (!workHistories && !workHistories.length) {
        return;
      }

      const currentActualManHourHistories = getCurrentActualManHourHistories(
        client,
        workHistories
      );

      if (currentActualManHourHistories.length) {
        // Remove current month
        // just display before this month
        currentActualManHourHistories.shift();
      }

      // 突然だが、実績工数をfirestoreから持ってくる
      const actualHours= await firebase.clientActualWorkHistories(params.id);

      setWorkHistories(workHistories);
      setTotalActualHour(getTotalActualHour(actualHours));
      setTotalPresentedHour(
        getTotalPresentedHour(currentActualManHourHistories)
      );

      // 実績工数履歴で使う値を 割当
      //setActualManHourHistories(currentActualManHourHistories);
      setActualManHourHistories(actualHours);
    };

    const cu = firebase.client(params.id).onSnapshot(async (sn) => {
      //
      // setTotalActualHour(0);
      // setTotalPresentedHour(0);
      // setActualManHourHistories([]);
      // const tasks = await firebase.db
      //   .collection("tasks")
      //   .where("options.clientId", "==", params.id)
      //   .where("status", "==", "pending")
      //   .get();
      // // watch for Events for local changes
      // // When performing a write, your listeners will be notified with the new data before the data is sent to the backend
      // if (sn.metadata.hasPendingWrites && !tasks.size) {
      //
      //   setPendingWorkHistories(true);
      //   const clientWorkHistories = await firebase
      //     .clientWorkHistories(params.id)
      //     .get();
      //   if (!clientWorkHistories.empty) {
      //     await initializeClientData(clientWorkHistories);
      //   }
      //   setPendingWorkHistories(false);
      // }
    });

    const getClient = async (clientID) => {
      try {
        setPending(true);
        const client = await firebase.client(clientID).get();

        if (!client.exists) {
          throw new Error("Client doesn't exists.");
        }

        const clientData = client.data();

        setValues({
          ...clientData,
          contractPeriodStart:
            typeof clientData.contractPeriodStart.toDate === "function"
              ? clientData.contractPeriodStart.toDate()
              : null,
          contractPeriodEnd:
            typeof clientData.contractPeriodEnd.toDate === "function"
              ? clientData.contractPeriodEnd.toDate()
              : null,
        });

        const ld = getDate(clientData.lastSync);
        setLastSync(ld ? moment(ld).format(FORMAT.DEFAULT_DATE_FORMAT) : "");
        setPending(false);

        unsubscribe = firebase.db
          .collection("tasks")
          .where("options.clientId", "==", params.id)
          .where("status", "==", "pending")
          .onSnapshot(async (docSnaphot) => {
            if (docSnaphot.empty) {
              setPendingWorkHistories(true);
              const clientWorkHistories = await firebase
                .clientWorkHistories(params.id)
                .get();

              if (!clientWorkHistories.empty) {
                await initializeClientData(clientWorkHistories);
              }

              setPendingWorkHistories(false);

              return;
            }

            setWorkHistories([]);
            setTotalActualHour(0);
            setTotalPresentedHour(0);
            setActualManHourHistories([]);
            setPendingWorkHistories(true);
          });
      } catch (ex) {
      } finally {
        setPending(false);
      }
    };

    getClient(params.id);

    return () => {
      unsubscribe();
      cu();
    };
  }, [params.id]);

  if (pending) {
    return (
      <>
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }

  return (
    <Can
      role={currentUser.role}
      permission="update:clients"
      no={<Redirect to={ROUTE.HOME} />}
    >
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={(e) => {
            history.push("/");
          }}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>クライアント詳細 (管理)</Breadcrumb.Item>
        <Breadcrumb.Item 
          href={"./"+params.id+"/details"}
        >{t("Client details")}</Breadcrumb.Item>
      </Breadcrumb>

      <h1 className="my-5 text-center">{values.name}</h1>

      <ClientFormProvider onSubmit={() => {}}>
        <ClientForm defaultValues={values} action="update" />
      </ClientFormProvider>

      <ActualManHourHistories
        histories={actualManHourHistories}
        actualHours={totalActualHour}
        presentedManHours={totalPresentedHour}
        lastSync={lastSync}
        pendingWorkHistories={pendingWorkHistories}
        clientCode={values.code}
        buffer2={values.buffer2}
      />

      <WorkHistories
        histories={workHistories}
        pendingWorkHistories={pendingWorkHistories}
        clientCode={values.code}
      />
    </Can>
  );
}
