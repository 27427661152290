import React from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useTranslation } from "react-i18next";

import Can from "../../../components/Can";

import { AuthContext } from "../../../context/Auth";

import firebase from "../../../services/firebase";
import ClientForm from "../../../components/ClientForm";

import * as ROUTE from "../../../constants/route";
import FORMAT from "../../../constants/format";
import ContractTimeProgress from "./ContractTimeProgress";
import MonthlyContractTimeProgress from "./MonthContractTimeProgress";
import {
  getDate,
  getMonthlyManHourMax,
  getCurrentActualManHourHistories,
  getWorkHistoriesDocuments,
  getTotalPresentedHour,
  getTotalActualHour,
  isUserAdmin,
} from "../../../utils/helpers";
import { firestore } from "firebase";

export default function (props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = React.useContext(AuthContext);
  const [pending, setPending] = React.useState(true);
  const params = useParams();
  const [values, setValues] = React.useState({});
  const [lastSync, setLastSync] = React.useState("");
  const [actualCurrentMonth, setActualCurrentMonth] = React.useState({});
  const [actualManHours, setActualManHours] = React.useState([]);
  const [totalActualHour, setTotalActualHour] = React.useState(0);
  const [totalPresentedHour, setTotalPresentedHour] = React.useState(0);


  // 副作用フック
  React.useEffect(() => {


    // 一般ユーザ用 ... firestoreコレクション:clientsのドキュメント内 actualWorkHistoriesのデータを使う
    const getClientByEmail = async (email) => {
      setPending(true);

      // クライアント情報
      const client = await firebase
        .clients()
        .where("email", "==", email)
        .limit(1)
        .get();

      // クライアントの契約・工数情報
      if (client.size === 1) {

        const clientData = { ...client.docs[0].data(), id: client.docs[0].id };
        const actualHours = clientData.actualWorkHistories;

        // クライアントの契約・契約期間・最終集計日時の表示情報をセット
        setValues({
          ...clientData,
          contractPeriodStart: getDate(clientData.contractPeriodStart),
          contractPeriodEnd: getDate(clientData.contractPeriodEnd),
        });
        setLastSync(getDate(clientData.lastSync, FORMAT.DEFAULT_DATE_FORMAT));


        const actualHourCurrentMonth = actualHours.filter(
          ({ yearMonth }) => yearMonth === moment().format(FORMAT.YEAR_MONTH)
        );
        // 契約期間での工数集計（累計）
        setTotalPresentedHour(getTotalPresentedHour(actualHours));



        //今月分の工数（累計）.... 契約期間中の最新/最後に位置する集計結果        
        if (actualHourCurrentMonth.length) {
          setActualCurrentMonth(actualHourCurrentMonth[0]);
        }

        // 過去実績 月別集計（累計）
        setActualManHours(actualHours);
      }

      setPending(false);
    };




    // 管理ユーザ用 ... 
    const getClientByID = async (id) => {
      setPending(true);

      const INCLUDE_CURRENT_MONTH = true;

      // クライアント情報
      // firestoreコレクション:clients から actualWorkHistories データを取り出す
      const actualHours= await firebase.clientActualWorkHistories(id);


      // クライアントの契約・工数情報
      const [client, clientWorkHistories] = await Promise.all([
        firebase.client(id).get(),
        firebase.clientWorkHistories(id).get(),
      ]);

      // クライアントの契約情報
      const clientData = {
        ...client.data(),
        contractPeriodStart: getDate(client.data().contractPeriodStart),
        contractPeriodEnd: getDate(client.data().contractPeriodEnd),
      };

      const workHistories = getWorkHistoriesDocuments(clientWorkHistories);


      /// クライアントの契約・契約期間・最終集計日時の表示情報をセット
      setValues(clientData);
      setLastSync(getDate(clientData.lastSync, FORMAT.DEFAULT_DATE_FORMAT));

      // 契約期間 月ごとの工数集計
      const actualHourCurrentMonth = getCurrentActualManHourHistories(
        clientData,
        workHistories,
        INCLUDE_CURRENT_MONTH
      );

      if (actualHourCurrentMonth.length) {
        setActualCurrentMonth(actualHourCurrentMonth[0]);

        // Remove current month
        // display before this month only
        actualHourCurrentMonth.shift();
      }

      // 契約期間 月ごとの工数集計（累計）
      //setTotalPresentedHour(getTotalPresentedHour(actualHourCurrentMonth));
      setTotalPresentedHour(getTotalPresentedHour(actualHours));

      //今月分の工数集計（累計）.... 契約期間中の最新/最後に位置する集計結果
      setActualCurrentMonth(actualHours[0]);
      console.debug(actualHours[0]);

      // 過去実績 月別集計（累計）
      setActualManHours(actualHours);


      setPending(false);
    };



    // 管理者挙動のスイッチ ... 一般顧客ロールでログインしていれば閲覧に対する保護がない(他の顧客データが閲覧できる)
    if (params.id) {
      getClientByID(params.id);
    } else {
      getClientByEmail(currentUser.email);
    }
  }, [currentUser, params.id]);





  if (pending) {
    return (
      <>
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }

  const monthMaxManHour = getMonthlyManHourMax(values);




  return (
    <Can
      role={currentUser.role}
      permission="view:clients"
      no={<Redirect to={ROUTE.HOME} />}
    >
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={(e) => {
            history.push("/");
          }}
        >
          Home
        </Breadcrumb.Item>



        {isUserAdmin(currentUser) && (
          <Breadcrumb.Item
            href={ "../" + params.id }
          >
            クライアント詳細 (管理)
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item active>{t("Client details")}</Breadcrumb.Item>
      </Breadcrumb>
      <ClientForm defaultValues={values} view client />
      <Table
        responsive
        className="table table--clients table-bordered border border-primary"
      >
        <tbody>
          <tr className="border border-primary">
            <td className="w-25 align-middle bg-primary text-light text-center p-1">
              {t("aggregationDate")}
            </td>
            <td>
              <Form.Control
                readOnly
                value={lastSync}
                className={`border-0 bg-light shadow-none`}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="d-flex align-items-center my-3 subtitle">
        <span
          style={{
            border: "2px solid black",
            width: "15px",
            height: "15px",
            display: "inline-block",
          }}
          className="mx-2"
        >
          {" "}
        </span>
        契約期間（累計）
      </div>
      <ContractTimeProgress
        volume={totalPresentedHour}
        maxConsumed={values.contractManHour}
      />


      <div className="d-flex align-items-center my-3 subtitle">
        <span
          style={{
            border: "2px solid black",
            width: "15px",
            height: "15px",
            display: "inline-block",
          }}
          className="mx-2"
        >
          {" "}
        </span>
        今月（累計）
      </div>
      <ContractTimeProgress
        volume={actualCurrentMonth.presentedManHour}
        maxConsumed={monthMaxManHour}
      />



      {!!actualManHours.length && (
        <>
          <div className="d-flex align-items-center my-3 subtitle">
            <span
              style={{
                border: "2px solid black",
                width: "15px",
                height: "15px",
                display: "inline-block",
              }}
              className="mx-2"
            >
              {" "}
            </span>
            過去実績（累計）
          </div>
          <ul className="p-0">
            {actualManHours.map((ah, index) => (
              <li className="d-flex" key={index}>
                <MonthlyContractTimeProgress
                  volume={parseFloat(Number((ah.manHour*(values.buffer2/100)) || 0)).toFixed(2)}
                  maxConsumed={Number(monthMaxManHour)}
                  month={ah.yearMonth}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </Can>
  );
}
