import React from "react";
import Table from "react-bootstrap/Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import firebase from "../../../services/firebase";
import * as ROUTE from "../../../constants/route";

const PER_PAGE = 25;

export default function (props) {
  const [logs, setLogs] = React.useState([]);
  const [pending, setPending] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { t } = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    const getLogs = async () => {
      try {
        const logs = await firebase.logs().get();

        if (logs.empty) {
          return;
        }

        setLogs(
          logs.docs.map((d) => {
            const data = { ...d.data() };
            return {
              ...data,
              id: d.id,
              date: moment(data.context.timestamp).format(
                "YYYY-MM-DD HH:MM:SS"
              ),
              eventId: data.context.eventId,
              eventType: data.context.eventType,
              resourceName: data.context.resource.name,
              resourceService: data.context.resource.service,
            };
          })
        );
      } catch (err) {
        console.error(err);
      } finally {
        setPending(false);
      }
    };

    getLogs();
  }, []);

  const indexOfLastClient = currentPage * PER_PAGE; // PER PAGE
  const indexOfFirstClient = indexOfLastClient - PER_PAGE;
  const currentLogs = logs.slice(indexOfFirstClient, indexOfLastClient);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={(e) => {
            history.push(ROUTE.HOME);
          }}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{t("Batch error log")}</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="text-center my-5">
        {t("Execution result of periodic data acquisition")}
      </h1>
      <Table responsive striped bordered hover>
        <thead>
          <tr className="bg-primary text-light text-center">
            <th style={{ verticalAlign: "middle" }}>{t("Date")}</th>
            <th style={{ verticalAlign: "middle" }}>Resource Name</th>
            <th style={{ verticalAlign: "middle" }}>Resource Service</th>
            <th style={{ verticalAlign: "middle" }}>Message</th>
            <th style={{ verticalAlign: "middle" }}>Event Type</th>
          </tr>
        </thead>
        <tbody>
          {pending && (
            <tr>
              <td colSpan={5} className="text-center">
                <Spinner size="sm" animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {!pending && !currentLogs.length && (
            <tr>
              <td colSpan={5} className="text-center">
                {t("No Data")}
              </td>
            </tr>
          )}
          {currentLogs.map((log, idx) => (
            <tr key={idx}>
              <td className="text-center">{log.date}</td>
              <td className="text-center">{log.resourceName}</td>
              <td className="text-center">{log.resourceService}</td>
              <td className="text-center">{log.message}</td>
              <td className="text-center">{log.eventType}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={PER_PAGE}
          totalItemsCount={logs.length}
          onChange={(page) => setCurrentPage(page)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  );
}
