/**
 * コンポーネントパーツ(管理者画面用): WorkHistories
 */
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FileDownload from "js-file-download";
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import FormGroup from "react-bootstrap/FormGroup";
import Button from "react-bootstrap/Button";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { orderBy } from "lodash";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import firebase from "../../../../../services/firebase";
import {
  getWorkHistoriesDocuments,
  exportXLSX,
  getDate,
} from "../../../../../utils/helpers";
import usePagination from "../../../../../hooks/usePagination";
import CONSTANTS from "../../../../../constants/common";

// "作業履歴"既定のテーブルソート
const INITIAL_SORT = {
  key: "created",
  direction: CONSTANTS.DESC,
};


/**
 * コンポーネントパーツ: 作業履歴
 * @param {*} param0 
 * @returns 
 */
function WorkHistories({ histories, pendingWorkHistories, clientCode }) {
  const params = useParams();
  const { t } = useTranslation();
  const [
    isDownloadingWorkHistoriesCSV,
    setIsDownloadingWorkHistoriesCSV,
  ] = React.useState(false);
  const [dueDateStart, setDueDateStart] = React.useState(
    moment().subtract(1, "month").toDate()
  );
  const [dueDateEnd, setDueDateEnd] = React.useState(moment().toDate());
  const [invalidFilterMessage, setInvalidFilterMessage] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [sort, setSort] = React.useState(INITIAL_SORT);
  const [workHistories, setWorkHistories] = React.useState(histories);
  const pagination = usePagination(workHistories);


  // 副作用フック
  React.useEffect(() => {
    setWorkHistories(histories);
  }, [histories]);


  // buttonHandle
  const handleExportClientWorkHistory = async (e) => {
    try {
      setIsDownloadingWorkHistoriesCSV(true);
      const filename = `${clientCode}_work_histories_${moment().format(
        "YYYY_MM_DD_HH_MM_SS"
      )}`;
      const DEFAULT_FORMAT_DATE = "YYYY/MM/DD";

      exportXLSX(
        histories.map((d) => {
          const createdDate = getDate(d.created);
          const updatedDate = getDate(d.updated);
          const startDate = getDate(d.startDate);
          const dueDate = getDate(d.dueDate);

          return {
            課題キー: d.issueKey,
            種別: d.issueTypeName,
            登録日: createdDate && moment(createdDate).format(DEFAULT_FORMAT_DATE),
            更新日: updatedDate && moment(updatedDate).format(DEFAULT_FORMAT_DATE),
            開始日: startDate && moment(startDate).format(DEFAULT_FORMAT_DATE),
            期限日: dueDate && moment(dueDate).format(DEFAULT_FORMAT_DATE),
            "予定工数(h)": d.estimatedHours,
            "実績工数(h)": d.actualHours,
          };
        }),
        filename
      );
    } catch (ex) {
      toast.error(t("Failed to download the work history csv."));
    } finally {
      setIsDownloadingWorkHistoriesCSV(false);
    }
  };

  const handleOnChangeDate = (date, dateName) => {
    setInvalidFilterMessage("");

    if (dateName === "start") {
      setDueDateStart(date);
    }

    if (dateName === "end") {
      setDueDateEnd(date);
    }

    if (!dueDateStart || !dueDateEnd) {
      setInvalidFilterMessage(t("Selected Date is invalid."));
    }

    const dateStart = moment(dateName === "start" ? date : dueDateStart);
    const dateEnd = moment(dateName === "end" ? date : dueDateEnd);

    if (dateStart.unix() > dateEnd.unix()) {
      setInvalidFilterMessage(t("Selected Date is invalid."));
    }
  };

  const handleFilterSearch = async () => {
    try {
      setInvalidFilterMessage("");
      if (!dueDateStart || !dueDateEnd) {
        setInvalidFilterMessage(t("Selected Date is invalid."));

        return;
      }

      const dateStart = moment(dueDateStart);
      const dateEnd = moment(dueDateEnd);

      if (dateStart.diff(dateEnd) >= 0) {
        setInvalidFilterMessage(t("Selected Date is invalid."));

        return;
      }

      setSearching(true);

      const results = await firebase
        .workHistories()
        .where("clientId", "==", params.id)
        .where("dueDate", ">=", dateStart.toDate())
        .where("dueDate", "<=", dateEnd.toDate())
        .get();

      pagination.setCurrentPage(1);
      const workHistoriesDocuments = results.empty
        ? []
        : getWorkHistoriesDocuments(results);
      setWorkHistories(
        orderBy(
          workHistoriesDocuments,
          [INITIAL_SORT.key],
          [INITIAL_SORT.direction]
        )
      );
      setSort(INITIAL_SORT);
    } catch (ex) {
    } finally {
      setSearching(false);
    }
  };

  const handleSort = (key) => {
    if (!workHistories.length) {
      return;
    }

    const direction =
      sort.key === key && sort.direction === CONSTANTS.ASC
        ? CONSTANTS.DESC
        : CONSTANTS.ASC;

    setWorkHistories(orderBy(workHistories, [`${key}`], [direction]));
    setSort({ key, direction });
  };

  const getSortIcon = (key) => {
    if (key !== sort.key) {
      return faSort;
    }

    return sort.direction === CONSTANTS.ASC ? faSortUp : faSortDown;
  };
  const getSortColor = (key) => ({
    color: sort.key !== key ? "#cecece" : "black",
  });

  return (
    <>
      <h3 className="my-4 clients--basic-info__header d-flex justify-content-between align-items-center">
        <span>{t("workHistory")}</span>
        <Button
          type="button"
          className="btn btn-primary"
          onClick={handleExportClientWorkHistory}
          style={{ minWidth: "100px" }}
          disabled={isDownloadingWorkHistoriesCSV}
        >
          {isDownloadingWorkHistoriesCSV ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "EXCEL"
          )}
        </Button>
      </h3>

      <Form inline className="mt-5 mb-2" noValidate validated={false}>
        <Row>
          <FormGroup>
            <Form.Label className="ml-4 mr-2">{t("period")}:</Form.Label>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              autoComplete="off"
              type="date"
              name="dueDateStart"
              className="form-control mr-2"
              selected={dueDateStart}
              onChange={(date) => handleOnChangeDate(date, "start")}
            />
          </FormGroup>
          <FormGroup>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              autoComplete="off"
              type="date"
              name="dueDateEnd"
              className="form-control mr-2"
              selected={dueDateEnd}
              onChange={(date) => handleOnChangeDate(date, "end")}
            />
          </FormGroup>
          <Button
            disabled={searching}
            type="button"
            onClick={handleFilterSearch}
            style={{ minWidth: "100px" }}
          >
            {searching ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t("send")
            )}
          </Button>
        </Row>
      </Form>
      {invalidFilterMessage && (
        <Row>
          <Form.Text className="text-danger" style={{ marginLeft: "4.5rem" }}>
            {invalidFilterMessage}
          </Form.Text>
        </Row>
      )}

      <Table
        responsive
        className="table table-bordered border border-primary mt-4 wf-table-layout-fixed wf-break-word"
      >
        <thead>
          <tr>
            <th>{t("Issue key")}</th>
            <th>{t("Issue type")}{" "}
            <FontAwesomeIcon
                className="float-right wf-hover"
                icon={getSortIcon("issueTypeName")}
                style={getSortColor("issueTypeName")}
                onClick={() => handleSort("issueTypeName")}
              />
            </th>
            <th>
              {t("Registration date")}{" "}
              <FontAwesomeIcon
                className="float-right wf-hover"
                icon={getSortIcon("created")}
                style={getSortColor("created")}
                onClick={() => handleSort("created")}
              />
            </th>
            <th>
              {t("Update date")}{" "}
              <FontAwesomeIcon
                className="float-right wf-hover"
                style={getSortColor("updated")}
                icon={getSortIcon("updated")}
                onClick={() => handleSort("updated")}
              />
            </th>
            <th>
              {t("Start date")}{" "}
              <FontAwesomeIcon
                className="float-right wf-hover"
                style={getSortColor("startDate")}
                icon={getSortIcon("startDate")}
                onClick={() => handleSort("startDate")}
              />
            </th>            
            <th>
              {t("Deadline")}{" "}
              <FontAwesomeIcon
                className="float-right wf-hover"
                style={getSortColor("dueDate")}
                icon={getSortIcon("dueDate")}
                onClick={() => handleSort("dueDate")}
              />
            </th>
            <th>
              {t("Estimated man-hours (h)")}{" "}
              <FontAwesomeIcon
                  className="float-right wf-hover"
                  style={getSortColor("estimatedHours")}
                  icon={getSortIcon("estimatedHours")}
                  onClick={() => handleSort("estimatedHours")}
                />            
            </th>            
            <th>
              {t("Actual man-hours (h)")}{" "}
              <FontAwesomeIcon
                  className="float-right wf-hover"
                  style={getSortColor("actualHours")}
                  icon={getSortIcon("actualHours")}
                  onClick={() => handleSort("actualHours")}
                />            
            </th>
          </tr>
        </thead>
        <tbody>
          {pendingWorkHistories && (
            <tr>
              <td colSpan={6} className="text-center">
                <Spinner animation="border" variant="primary" />
                <p className="text-muted" style={{ fontSize: "0.8em" }}>
                  {t("Generating work histories from backlog")}
                </p>
              </td>
            </tr>
          )}

          {!pendingWorkHistories && !pagination.items.length && (
            <tr>
              <td colSpan={6} className="text-center">
                <p className="text-muted">{t("No Data")}</p>
              </td>
            </tr>
          )}

          {pagination.items.map((w, index) => (
            <tr key={index}>
              <td>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://c-com.backlog.jp/view/${w.issueKey}`}
                >
                  {w.issueKey}
                </a>
              </td>
              <td>{w.issueTypeName}</td>
              <td>{w.created}</td>
              <td>{w.updated}</td>
              <td>{w.startDate}</td>
              <td>{w.dueDate}</td>
              <td>{w.estimatedHours}</td>
              <td>{w.actualHours}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <Pagination
          activePage={pagination.currentPage}
          itemsCountPerPage={pagination.perPage}
          totalItemsCount={pagination.total}
          onChange={(page) => pagination.setCurrentPage(page)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  );
}

export default WorkHistories;
