import React from "react";

const PER_PAGE = 15;

export default function usePagination(data, perPage = null) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const dataPerPage = perPage ? perPage : PER_PAGE;
  const indexOfLastClient = currentPage * dataPerPage; // PER PAGE
  const indexOfFirstClient = indexOfLastClient - dataPerPage;
  const items = data.slice(indexOfFirstClient, indexOfLastClient);

  return {
    items,
    currentPage,
    setCurrentPage,
    perPage: dataPerPage,
    total: data.length,
  };
}
