import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";

import * as ROUTE from "../../constants/route";

function NotFoundPage() {
  return (
    <Container className="d-flex justify-content-center align-items-center h-100 flex-column">
      <p className="not-found-text">
        404: The page you are looking for isn’t here
      </p>
      <Link to={ROUTE.HOME}>Return Home</Link>
    </Container>
  );
}

export default NotFoundPage;
