import React from "react";
import { useForm, FormContext } from "react-hook-form";
import Form from "react-bootstrap/Form";

import BasicInformation from "./BasicInformation";
import ContractContent from "./ContractContent";
import * as ValidationRule from "./validation-rules";

export default function ({
  defaultValues = {
    contractManHour: "",
    buffer: 150,
    buffer2: 150,
  },
  action = "register",
  view = false,
  client = false,
}) {
  const methods = useForm({
    mode: "onblur",
    validationResolver:
      action === "register"
        ? ValidationRule.resolver
        : ValidationRule.resolverUpdate,
    defaultValues,
  });

  return (
    <FormContext view={view} action={action} {...methods}>
      <Form>
        {!client && <BasicInformation />}
        <ContractContent client={client} />
      </Form>
    </FormContext>
  );
}
