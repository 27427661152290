import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import roles from "../constants/roles";
import FORMAT from "../constants/format";

export const validatorResolver = (validationSchema) => {
  return (data, validationContext) => {
    const { error, value: values } = validationSchema.validate(data, {
      abortEarly: false,
    });

    return {
      values: error ? {} : values,
      errors: error
        ? error.details.reduce((previous, currentError) => {
            return {
              ...previous,
              [currentError.path[0]]: currentError,
            };
          }, {})
        : {},
    };
  };
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const getMonthlyManHourMax = ({
  contractPeriodStart,
  contractPeriodEnd,
  contractManHour,
  buffer,
}) => {
  const numberOfMonthInContract = parseInt(
    Math.round(moment(contractPeriodEnd).diff(contractPeriodStart, "months", true))
  );
  //console.debug('not paseInt:'+moment(contractPeriodEnd).diff(contractPeriodStart, "months", true))
  //console.debug('numberOfMonthInContract:'+numberOfMonthInContract)

  if (!numberOfMonthInContract) {
    return 0;
  }
  return Number((contractManHour / numberOfMonthInContract) * (buffer/100)).toFixed(2); // constant
};

export const isUserAdmin = (user) => user && user.role === roles.ADMIN;

export const getProgressStyle = (volume, maxConsumed, fullPercent = 0) => {
  let calculatedVolume = 0;

  if (maxConsumed) {
    calculatedVolume = (Number(volume) / Number(maxConsumed)) * 75;
  }

  let styleProgress = {
    width: `${
      calculatedVolume > 3 ? calculatedVolume - 3 : calculatedVolume / 3
    }%`,
  };

  if (calculatedVolume >= 75) {
    // equal to 100%
    return {
      ...styleProgress,
      backgroundColor: "#d12f2a",
    };
  }

  if (calculatedVolume < 37.5) {
    return {
      ...styleProgress,
      backgroundColor: "#c1e2a1",
    };
  }

  if (calculatedVolume >= 37.5) {
    // 50%
    return {
      ...styleProgress,
      backgroundColor: "#fcd736",
    };
  }

  return styleProgress;
};

/**
 * 
 * @param {*} histories 
 * @param {*} client 
 * @returns 
 */
export const getActualManHourHistories = (histories, client) => {
  const manHourHistories = {};
  const fd = moment(client.contractPeriodStart).startOf("month");
  const ld = moment(client.contractPeriodEnd).isSameOrBefore(moment())
    ? moment() // .subtract(1, "month")
    : moment(client.contractPeriodEnd);

  // build yearn month
  for (let i = fd; i.isSameOrBefore(ld); i.add(1, "day")) {
    const yearMonth = moment(i).format("YYYY-MM");

    manHourHistories[yearMonth] = {
      manHour: 0,
      presentedManHour: 0,
      yearMonthUnix: moment(yearMonth).unix(),
      yearMonth: moment(yearMonth).format("YYYY年M月"),
    };
  }

  histories
    .filter(({ startDate }) => !!startDate)
    .forEach((history) => {
      // startDate
      const yearMonth = moment(history.startDate).format("YYYY-MM");

      if (manHourHistories[yearMonth]) {
        manHourHistories[yearMonth].manHour += Number(history.actualHours);
        manHourHistories[yearMonth].presentedManHour =
          (manHourHistories[yearMonth].manHour || 0) *
          ((client.buffer || 0) / 100);
        manHourHistories[yearMonth].yearMonthUnix = moment(yearMonth).unix();
        manHourHistories[yearMonth].yearMonth = moment(yearMonth).format("YYYY年M月");
      }
    });

  return Object.keys(manHourHistories)
    .map((k) => ({
      ...manHourHistories[k],
      manHour: parseFloat(Number(manHourHistories[k].manHour).toFixed(2)),
      presentedManHour: parseFloat(
        Number(manHourHistories[k].presentedManHour).toFixed(2)
      ),
    }))
    .sort((a, b) => b.yearMonthUnix - a.yearMonthUnix);
};

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const getWorkHistoriesDocuments = (data) => {
  if (!data && !data.size) {
    return [];
  }

  return data.docs.map((d) => {
    const docData = d.data();

    return {
      ...docData,
      id: d.id,
      startDate: moment(docData.startDate).format(FORMAT.EN_DATE_FORMAT),
      dueDate: docData.dueDate
        ? moment(docData.dueDate.toDate()).format(FORMAT.EN_DATE_FORMAT)
        : null,
      created: moment(docData.created).format(FORMAT.EN_DATE_FORMAT),
      updated: moment(docData.updated).format(FORMAT.EN_DATE_FORMAT),
    };
  });
};

/**
 * Convert date
 *
 * @param {*} value
 * @param {*} format
 */
export const getDate = (value, format = null) => {
  let dateValue = value;

  if (!dateValue) {
    return null;
  }
  if (typeof dateValue === "string") {
    const date = moment(value);

    if (date.isValid()) {
      dateValue = date.toDate();
    }
  }

  /**
   * Firebase date instance
   */
  if (typeof value.toDate === "function") {
    dateValue = value.toDate();
  }

  if (format) {
    return moment(dateValue).format(format);
  }

  return dateValue;
};

export const toPercentage = (value) => (value || 0) / 100;

export const toDecimal = (value, decimalPoint = 2) =>
  parseFloat(Number(value || 0).toFixed(decimalPoint));

export const topCollections = (data, size) =>
  data && data.length ? data.slice(0, size || 0) : [];

export const gg = (clientDoc, clientWorkHistories) => {
  const client = {
    ...clientDoc,
    contractPeriodStart: getDate(clientDoc.contractPeriodStart),
    contractPeriodEnd: getDate(clientDoc.contractPeriodEnd),
  };
  const diffMonth = moment(clientDoc.contractPeriodEnd).diff(
    client.contractPeriodStart,
    "months"
  );
  const workHistories = getWorkHistoriesDocuments(clientWorkHistories);
  const currentActualManHourHistories = getActualManHourHistories(
    workHistories.filter(
      ({ created }) =>
        moment(created).isSameOrAfter(client.contractPeriodStart) &&
        moment(created).isSameOrBefore(client.contractPeriodEnd)
    ),
    client
  );
  const topActualManHourHistories = topCollections(
    currentActualManHourHistories,
    currentActualManHourHistories >= 12 ? 11 : diffMonth
  );
  const totalActualHour = toDecimal(
    topActualManHourHistories
      .map(({ manHour }) => Number(manHour))
      .reduce((a, b) => a + b)
  );
  const totalPresentedHour = toDecimal(
    topActualManHourHistories
      .map(({ presentedManHour }) => Number(presentedManHour))
      .reduce((a, b) => a + b)
  );

  return {
    client,
    totalActualHour,
    totalPresentedHour,
  };
};

export const getTotalActualHour = (workHistories) => {
  if (!workHistories || !workHistories.length) {
    return 0;
  }

  const totalActualHour = toDecimal(
    workHistories.map(({ manHour }) => Number(manHour)).reduce((a, b) => a + b)
  );

  return totalActualHour;
};

export const getTotalPresentedHour = (workHistories) => {
  if (!workHistories || !workHistories.length) {
    return 0;
  }

  const totalPresentedHour = toDecimal(
    workHistories
      .map(({ presentedManHour }) => Number(presentedManHour))
      .reduce((a, b) => a + b)
  );

  return totalPresentedHour;
};


/**
 * 
 * @param {*} currentActualManHourHistories 
 * @returns 
 */
export const getTopActualManHours = (currentActualManHourHistories) => {
  if (!currentActualManHourHistories || !currentActualManHourHistories.length) {
    return [];
  }


  const topActualManHourHistories = topCollections(
    currentActualManHourHistories,
    currentActualManHourHistories.length >= 12
      ? 11
      : currentActualManHourHistories.length
  );

  return topActualManHourHistories;
};

export const getCurrentActualManHourHistories = (client, workHistories) => {
  if (!workHistories || !workHistories.length) {
    return [];
  }

  const currentActualManHourHistories = getActualManHourHistories(
    workHistories.filter(
      ({ created }) =>
        moment(created).isSameOrAfter(client.contractPeriodStart) &&
        moment(created).isSameOrBefore(client.contractPeriodEnd)
    ),
    client
  );

  const topActualManHours = getTopActualManHours(currentActualManHourHistories);

  return topActualManHours;
};

export const exportXLSX = (csvData, filename) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, filename + fileExtension);
};
