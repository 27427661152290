import PropTypes from "prop-types";
import rules from "../../rules";

const isAllowed = (role, permission) => {
  return rules[role].includes(permission);
};

const Can = ({ children, no, role, permission }) =>
  isAllowed(role, permission) ? children : no;

Can.protoTypes = {
  children: PropTypes.element.isRequired,
  no: PropTypes.element.isRequired,
  role: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
};

export default Can;
