import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import qs from "query-string";

import { AuthProvider } from "../../context/Auth";
import Layout from "../../layout";

import Routes from "../../routes";

import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import LayoutProvider from "../../context/Layout";

const getQueryStringValue = (key) => {
  const values = qs.parseUrl(window.location.href);

  return values.query[key] || "ja";
};

function App(props) {
  const { i18n } = useTranslation();
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!initialized) {
      i18n.changeLanguage(getQueryStringValue("lang"));
      setInitialized(true);
    }
  }, [initialized, i18n]);

  return (
    <Router>
      <AuthProvider>
        <LayoutProvider>
          <Layout>
            <>
              <ToastContainer hideProgressBar position="top-center" />
              <Routes />
            </>
          </Layout>
        </LayoutProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
