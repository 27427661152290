import React from "react";

export default function ({ label, controlInput }) {
  return (
    <tr className="border border-primary">
      <td className="w-25 align-middle bg-primary text-light text-center">
        {label}
      </td>
      <td>{controlInput}</td>
    </tr>
  );
}
