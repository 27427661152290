import React from "react";
import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { LayoutContext } from "../context/Layout";

import Sidenav from "./Sidenav";
import Topnav from "./Topnav";

import "./index.css";

function LogouConfirmationModal({ onYesLogout, onCancelLogout, ...props }) {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p>{t("Can I log out?")}</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button onClick={onYesLogout}>{t("yes")}</Button>
        <Button onClick={onCancelLogout}>{t("no")}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ({ children }) {
  const {
    modalShow,
    setModalShow,
    isMDScreen,
    handleLogout,
  } = React.useContext(LayoutContext);

  return (
    <div className="d-flex app-container flex-sm-column flex-md-row height-100">
      {/* SHOW WHEN SCREEN IS MEDIUM TO LARGE */}
      {isMDScreen && <Sidenav />}

      {/* THIS WILL SHOW WHEN SMALL SCREEN */}
      {<Topnav />}

      <Container fluid className="py-4">
        {children}
      </Container>
      <LogouConfirmationModal
        show={modalShow}
        onCancelLogout={() => setModalShow(false)}
        onYesLogout={handleLogout}
      />
    </div>
  );
}
