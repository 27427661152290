import Joi from "@hapi/joi";
import i18n from "../../i18n";

export const PASSWORD_REGEX = /^([a-zA-Z0-9@\-.+=_]{8,})$/;

export const validationSchema = Joi.object({
  code: Joi.string()
    .required()
    .messages({
      "string.empty": i18n.t("The client code is a required input item."),
      "any.required": i18n.t("The client code is a required input item."),
    }),
  name: Joi.string()
    .required()
    .messages({
      "string.empty": i18n.t("The client name is a required input item."),
      "any.required": i18n.t("The client name is a required input item."),
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": i18n.t("Please enter a valid email address."),
      "string.empty": i18n.t("Login ID (user name) is a required input item."),
      "any.required": i18n.t("Login ID (user name) is a required input item."),
    }),
  password: Joi.string()
    .required()
    .regex(PASSWORD_REGEX)
    .messages({
      "string.empty": i18n.t("Password is a mandatory input school."),
      "any.required": i18n.t("Password is a mandatory input school."),
      "string.pattern.base": i18n.t(
        "Password must at least 8 characters long and contains a special character (@-+.=_)."
      ),
    }),
  confirmPassword: Joi.string()
    .required()
    .valid(Joi.ref("password"))
    .messages({
      "string.empty": i18n.t("The password confirmation field is mandatory."),
      "any.only": i18n.t("The confirmation passwords did not match."),
      "any.required": i18n.t("The password confirmation field is mandatory."),
    }),
  contractManHour: Joi.number()
    .min(0)
    .required()
    .messages({
      "number.empty": i18n.t("A contract man-hour column is required."),
      "any.required": i18n.t("A contract man-hour column is required."),
      "number.base": i18n.t("The contract time must be a number."),
    }),
  monthlyManHourMax: Joi.number()
    .min(0)
    .required()
    .messages({
      "number.empty": i18n.t("A monthly maximum effort field is required."),
      "any.required": i18n.t("A monthly maximum effort field is required."),
    }),
  contractPeriodStart: Joi.date()
    .required()
    .iso()
    .messages({
      "date.base": i18n.t("The period start date is required."),
      "string.empty": i18n.t("The period start date is required."),
      "any.required": i18n.t("The period start date is required."),
      "date.min": i18n.t(
        "Cannot be set because the end date of the contract period is specified as a date before the start date."
      ),
      "date.format": i18n.t(
        "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD."
      ),
      "any.ref": i18n.t(
        "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD."
      ),
    }),
  buffer: Joi.number()
    .required()
    .min(0)
    .messages({
      "number.empty": i18n.t("The buffer field is mandatory."),
      "any.required": i18n.t("The buffer field is mandatory."),
      "number.base": i18n.t("The buffer must be numeric."),
    }),
  buffer2: Joi.number()
    .required()
    .min(0)
    .messages({
      "number.empty": i18n.t("The buffer2 field is mandatory."),
      "any.required": i18n.t("The buffer2 field is mandatory."),
      "number.base": i18n.t("The buffer2 must be numeric."),
    }),
  contractPeriodEnd: Joi.date()
    .iso()
    .required()
    .min(Joi.ref("contractPeriodStart"))
    .messages({
      "date.base": i18n.t("The contract period must end."),
      "string.empty": i18n.t("The contract period must end."),
      "any.required": i18n.t("The contract period must end."),
      "date.min": i18n.t(
        "Cannot be set because the end date of the contract period is specified as a date before the start date."
      ),
      "date.format": i18n.t(
        "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD."
      ),
      "any.ref": i18n.t(
        "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD."
      ),
    }),
});

export const resolver = (data, validationContext) => {
  const { error, value: values } = validationSchema.validate(data, {
    abortEarly: false,
  });

  return {
    values: error ? {} : values,
    errors: error
      ? error.details.reduce((previous, currentError) => {
          return {
            ...previous,
            [currentError.path[0]]: currentError,
          };
        }, {})
      : {},
  };
};

export const validationSchemaUpdate = Joi.object({
  code: Joi.string()
    .required()
    .messages({
      "string.empty": i18n.t("The client code is a required input item."),
      "any.required": i18n.t("The client code is a required input item."),
    }),
  name: Joi.string()
    .required()
    .messages({
      "string.empty": i18n.t("The client name is a required input item."),
      "any.required": i18n.t("The client name is a required input item."),
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": i18n.t("Please enter a valid email address."),
      "string.empty": i18n.t("Login ID (user name) is a required input item."),
      "any.required": i18n.t("Login ID (user name) is a required input item."),
    }),
  password: Joi.string()
    .regex(PASSWORD_REGEX)
    .allow("")
    .optional()
    .messages({
      "string.pattern.base": i18n.t(
        "Password must at least 8 characters long and contains a special character (@-+.=_)."
      ),
    }),
  confirmPassword: Joi.when("password", {
    is: "" || null,
    otherwise: Joi.string()
      .required()
      .valid(Joi.ref("password"))
      .messages({
        "any.only": i18n.t("The confirmation passwords did not match."),
        "string.empty": i18n.t("The password confirmation field is mandatory."),
        "any.required": i18n.t("The password confirmation field is mandatory."),
      }),
    then: Joi.string().allow("").optional(),
  }),
  contractManHour: Joi.number()
    .min(0)
    .messages({
      "number.empty": i18n.t("A contract man-hour column is required."),
      "any.required": i18n.t("A contract man-hour column is required."),
      "number.base": i18n.t("The contract time must be a number."),
    }),
  buffer: Joi.number()
    .min(0)
    .messages({
      "number.empty": i18n.t("The buffer field is mandatory."),
      "any.required": i18n.t("The buffer field is mandatory."),
      "number.base": i18n.t("The buffer must be numeric."),
    }),
  buffer2: Joi.number()
    .min(0)
    .messages({
      "number.empty": i18n.t("The buffer2 field is mandatory."),
      "any.required": i18n.t("The buffer2 field is mandatory."),
      "number.base": i18n.t("The buffer2 must be numeric."),
    }),
  contractPeriodStart: Joi.date()
    .iso()
    .messages({
      "date.base": i18n.t("The period start date is required."),
      "string.empty": i18n.t("The period start date is required."),
      "any.required": i18n.t("The period start date is required."),
      "date.min": i18n.t(
        "Cannot be set because the end date of the contract period is specified as a date before the start date."
      ),
      "date.format": i18n.t(
        "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD."
      ),
      "any.ref": i18n.t(
        "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD."
      ),
    }),
  contractPeriodEnd: Joi.date()
    .iso()
    .min(Joi.ref("contractPeriodStart"))
    .messages({
      "date.base": i18n.t("The contract period must end."),
      "string.empty": i18n.t("The contract period must end."),
      "any.required": i18n.t("The contract period must end."),
      "date.min": i18n.t(
        "Cannot be set because the end date of the contract period is specified as a date before the start date."
      ),
      "date.format": i18n.t(
        "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD."
      ),
      "any.ref": i18n.t(
        "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD."
      ),
    }),
});

export const resolverUpdate = (data, validationContext) => {
  const { error, value: values } = validationSchemaUpdate.validate(data, {
    abortEarly: false,
  });

  return {
    values: error ? {} : values,
    errors: error
      ? error.details.reduce((previous, currentError) => {
          return {
            ...previous,
            [currentError.path[0]]: currentError,
          };
        }, {})
      : {},
  };
};
