import React from "react";

import firebase from "../../../services/firebase";
import Login from "./components/Login";
import { AuthContext } from "../../../context/Auth";
import { Redirect } from "react-router-dom";
import * as ROUTE from "../../../constants/route";

export default function (props) {
  const { currentUser } = React.useContext(AuthContext);
  const handleSubmit = async ({ email, password }) => {
    await firebase.login(email, password);
  };

  // user already signed in will be redirected back to home
  if (currentUser) {
    return <Redirect to={ROUTE.HOME} />;
  }

  return (
    <>
      <Login onSubmit={handleSubmit} />
    </>
  );
}
