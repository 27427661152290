import React from "react";
import { useForm } from "react-hook-form";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import FormControlFeedback from "../../../../components/FormControlFeedback";

import { resolver } from "../validation-rules";

import "../index.css";

function Login({ onSubmit }) {
  const { t } = useTranslation();

  const [loggingIn, setLogginIn] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onBlur",
    validationResolver: resolver,
  });

  const handleSubmitLogin = async ({ email, password }) => {
    try {
      setErrorMessage("");
      setLogginIn(true);

      await onSubmit({ email, password });
    } catch (error) {
      setValue("password", "");
      setErrorMessage(
        t(
          "I could not login. Please check your user name (login ID) and password and try again."
        )
      );
    } finally {
      setLogginIn(false);
    }
  };

  return (
    <>
      <Col xl={{ span: 8, offset: 2 }} md={{ span: 12}} lg={{ span: 12 }}>
        <Form
          className="login-form"
          onSubmit={handleSubmit(handleSubmitLogin)}
          noValidate
        >
          <h1 className="text-center mb-5">{t("welcome")}</h1>
          <Form.Group as={Row} controlId="email" className="align-items-center">
            <Col lg={2} md={12}>
              <label htmlFor="email">{t("id")}</label>
            </Col>
            <Col lg={10} md={12}>
              <Form.Control
                type="email"
                name="email"
                autoComplete="off"
                placeholder={t("username")}
                ref={register({ required: true })}
                isInvalid={errors.email}
              />
              <FormControlFeedback error={errors.email} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="password" className="align-items-center">
            <Col lg={2} md={12}>
              <label htmlFor="password" style={{ wordBreak: "keep-all" }}>
                {t("password")}
              </label>
            </Col>

            <Col lg={10} md={12}>
              <Form.Control
                type="password"
                name="password"
                placeholder={t("password")}
                ref={register({ required: true })}
                isInvalid={errors.password}
              />
              <FormControlFeedback error={errors.password} />
            </Col>
          </Form.Group>
          {errorMessage && (
            <Form.Group as={Row}>
              <Col>
                <p className="text-center text-danger">{errorMessage}</p>
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} className="mt-5">
            <Col>
              <Button
                role="button"
                type="submit"
                className="w-100 login"
                disabled={loggingIn}
              >
                {loggingIn ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  t("login")
                )}
              </Button>
            </Col>
          </Form.Group>
        </Form>
        <p className="mt-5 text-center">
          {t("If you forget your login ID and password,")}
          <br />
          {t("Contact your sales representative or")}
          <br />
          {t("Please contact us from the inquiry form.")}
        </p>
      </Col>
    </>
  );
}

export default Login;
