import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import firebase from "../services/firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(async (user) => {
      if (user) {
        const { size } = await firebase
          .clients()
          .where("email", "==", user.email)
          .get();

        setCurrentUser({ ...user, role: size === 1 ? "client" : "admin" });
      } else {
        setCurrentUser(null);
      }

      setPending(false);
    });

    return () => unsubscribe();
  }, []);

  if (pending) {
    return (
      <>
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
