import React from "react";
import { getProgressStyle } from "../../../utils/helpers";

function MonthlyContractTimeProgress({ volume, maxConsumed, month }) {
  return (
    <>
      <div className="d-flex flex-grow-1 position-relative">
        <span
          style={{
            background: "#ff9b61",
            width: "1px",
            position: "absolute",
            height: "100%",
            left: "75%",
          }}
        ></span>
        <span className="my-auto mx-3" style={{ width: "105px" }}>
          {month}
        </span>
        <span
          style={{
            background: "#a5a5a5",
            width: "3px",
            margin: "0 5px",
          }}
        ></span>
        <div className="d-flex my-3 flex-grow-1" style={{ height: "15px" }}>
          <span
            className="h-100"
            style={getProgressStyle(volume, maxConsumed)}
          ></span>
        </div>
      </div>
      <span
        className="my-auto mx-2 pl-4 font-weight-bold"
        style={{ width: "20%" }}
      >
        消費工数: {`${Number(volume || 0).toFixed(2)} h`}
      </span>
    </>
  );
}

export default MonthlyContractTimeProgress;
