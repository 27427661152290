import axios from "axios";
import { HTTP_TIMEOUT } from "../../config";

class HttpClient {
  constructor(url = null) {
    this.axios = axios.create({
      baseURL: url || "/",
      // withCredentials: true, // send cookies when cross-domain requests
      timeout: HTTP_TIMEOUT,
    });
  }

  /**
   *
   * @param {string} url
   * @param {AxiosRequestConfig} config
   *
   * @return {Promise<AxiosResponse<any>>}
   */
  async get(url, config = {}) {
    return await this.axios.get(url, config);
  }

  /**
   * @param {string} url
   * @param {any} payload
   * @param {AxiosRequestConfig} config
   *
   * @return {Promise<AxiosResponse<any>>}
   */
  async post(url, payload, config = {}) {
    return await this.axios.post(url, payload, config);
  }

  /**
   * @param {string} url
   * @param {any} payload
   * @param {AxiosRequestConfig} config
   *
   * @return {Promise<AxiosResponse<any>>}
   */
  async put(url, payload, config = {}) {
    return await this.axios.put(url, payload, config);
  }
}

const http = new HttpClient();

export default http;
