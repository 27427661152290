import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { isUserAdmin } from "../utils/helpers";
import LogoVertical from "../assets/WHITE_02.png";
import * as ROUTE from "../constants/route";
import { AuthContext } from "../context/Auth";
import { LayoutContext } from "../context/Layout";

/**
 * Menu at Mobile.
 */

function Topnav() {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentUser } = React.useContext(AuthContext);
  const { setModalShow, handleInqueryLink } = React.useContext(LayoutContext);

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      variant="dark"
      className="d-flex d-lg-none justify-content-between md-navbar"
    >
      <Navbar.Brand>
        <img
          src={LogoVertical}
          alt="WillFind"
          id="logo"
          className="img-fluid wf-hover"
          style={{ maxWidth: "150px" }}
          onClick={() => history.push(ROUTE.HOME)}
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="ml-auto text-center">
          {isUserAdmin(currentUser) && (
            <>
              <Navbar.Text>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100"
                  onClick={() => history.push(ROUTE.CLIENTS)}
                >
                  {t("clientList")}
                </Button>
              </Navbar.Text>

              <Navbar.Text>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100"
                  onClick={() => history.push(ROUTE.CLIENTS_REGISTER)}
                >
                  {t("newClient")}
                </Button>
              </Navbar.Text>

              <Navbar.Text>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100"
                  onClick={() => history.push(ROUTE.LOGS)}
                >
                  {t("Batch error log")}
                </Button>
              </Navbar.Text>
            </>
          )}


          <Navbar.Text>
            <Button
              type="button"
              variant="primary"
              className="w-100"
              onClick={handleInqueryLink}
            >
              {t("inquiry")}
            </Button>
          </Navbar.Text>

          {currentUser && (
            <Navbar.Text>
              <Button
                type="button"
                variant="primary"
                className="w-100 logout"
                onClick={() => setModalShow(true)}
              >
                {t("logout")}
              </Button>
            </Navbar.Text>
          )}


        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Topnav;
