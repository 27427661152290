/**
 * コンポーネントパーツ: ContractContent / 顧客契約情報(契約期間や工数上限)
 */
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import pick from "lodash/pick";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import FormErrors from "../FormErrors";
import FormHeading from "./FormHeading";
import TableInputControlRow from "./TableInputControlRow";

import firebase from "../../services/firebase";
import { getMonthlyManHourMax } from "../../utils/helpers";
import CONSTANTS from "../../constants/common";

import "react-datepicker/dist/react-datepicker.css";
import { toNumber } from "lodash";

const contractContentKey = [
  "contractPeriodStart",
  "contractPeriodEnd",
  "contractManHour",
  "buffer",
  "buffer2",
];

/**
 * コンポーネントパーツ: 契約情報
 * @param {*} props 
 * @returns 
 */
function ContractContent(props) {
  const { t } = useTranslation();
  const params = useParams();
  const {
    watch,
    errors,
    triggerValidation,
    getValues,
    action,
    control,
    view,
    setValue,
  } = useFormContext();
  const [edit, setEdit] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  // 編集キャンセル時 復帰文字列を確保するためのやつ
  const [contractDefault, setContractDefault ] = React.useState(pick(getValues(), contractContentKey));
  //console.debug(contractDefault);
  // `params`は、URLのgetパラメータ



  /**
   * 更新時
   * @param {*} e 
   * @returns 
   */
  const handleUpdate = async (e) => {
    e.preventDefault();
    const isValid = await triggerValidation(contractContentKey);

    if (!isValid) {
      return;
    }

    setUpdating(true);

    const payload = pick(getValues(), contractContentKey);

    await firebase.client(params.id).update({
      ...payload,
      buffer: toNumber(payload.buffer),
      buffer2: toNumber(payload.buffer2),
      contractManHour: toNumber(payload.contractManHour),
      updatedAt: new Date(),
    });

    setUpdating(false);
    setEdit(false);

    // キャンセル時に復帰するデフォルト値を更新する
    setContractDefault(pick(getValues(), contractContentKey));

    toast.success("契約内容を更新しました。");
  };

  // In view or updating
  const notEditOrUpdating = !edit || updating;



  /**
   * 編集・キャンセルボタン:契約
   * @param {*} e 
   */
  const handleContract = async(e) =>{
    setEdit(!edit);

    //リセット時
    //  契約基本情報
    if(edit)
    {
      setValue("contractPeriodStart", contractDefault.contractPeriodStart);
      setValue("contractPeriodEnd",   contractDefault.contractPeriodEnd);
      setValue("contractManHour",     contractDefault.contractManHour); 
      setValue("buffer",              contractDefault.buffer); 
      setValue("buffer2",             contractDefault.buffer2); 
    } 
  }





  /**
   * 補助
   * @returns 
   */
  const renderAction = () => {
    if (action === CONSTANTS.UPDATE) {
      return (
        <>
          <Button className="w-25" onClick={handleContract}>
            {edit ? t("cancel") : t("edit")}
          </Button>

          <span className="mx-1"></span>
          <Button
            type="button"
            className="w-25"
            disabled={notEditOrUpdating}
            onClick={handleUpdate}
          >
            {updating ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              t("update")
            )}
          </Button>
        </>
      );
    }

    return null;
  };



  // 監視
  const {
    contractPeriodStart,
    contractPeriodEnd,
    contractManHour,
    name,
    buffer,
    buffer2,
  } = watch([
    "contractPeriodStart",
    "contractPeriodEnd",
    "contractManHour",
    "code",
    "name",
    "buffer",
    "buffer2",
  ]);








  // 
  return (
    <>
      
      {!props.client ? (
        <FormHeading title={t("contractContent")}>{renderAction()}</FormHeading>
      ) : (
        <h1 className="my-5 text-center">{name}</h1>
      )}

      {/* DISPLAY ERROR FOR UPDATE OR DURING REGISTRATION */}
      {(edit || action === "register") && (
        <FormErrors errors={pick(errors, contractContentKey)} />
      )}
      <Table
        responsive
        className="table table--clients table-bordered border border-primary"
      >
        <tbody>
          <TableInputControlRow
            label={t("contractPeriod")}
            controlInput={
              <Form.Group className="d-flex m-0">
                <Controller
                  name="contractPeriodStart"
                  disabled={(!edit && params.id) || view}
                  control={control}
                  className={`form-control border-0 bg-light ${
                    !edit ? "shadow-none" : ""
                  } ${!((!edit && params.id) || view) && "edit"}`}
                  as={
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      placeholderText={t("Enter contract period start")}
                      autoComplete="off"
                    />
                  }
                  valueName="selected"
                  onChange={([selected]) => selected}
                />
                <span className="my-auto px-2">～</span>
                <Controller
                  name="contractPeriodEnd"
                  disabled={view || (!edit && params.id)}
                  control={control}
                  className={`form-control border-0 bg-light ${
                    !edit ? "shadow-none" : ""
                  } ${!((!edit && params.id) || view) && "edit"}`}
                  as={
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      placeholderText={t("Enter contract period end")}
                      autoComplete="off"
                    />
                  }
                  valueName="selected"
                  onChange={([selected]) => selected}
                />
              </Form.Group>
            }
          />
          <TableInputControlRow
            label={t("Contract man-hour (h)")}
            controlInput={
              <Controller
                autoComplete="off"
                name="contractManHour"
                disabled={view || (!edit && params.id)}
                control={control}
                className={`form-control border-0 bg-light ${
                  !edit ? "shadow-none" : ""
                }`}
                as={
                  <NumberFormat
                    suffix={"h"}
                    defaultValue=""
                    placeholder={t("Enter contract effort")}
                  />
                }
                inputMode="numeric"
                onChangeName="onValueChange"
                onChange={([{ value }]) => value}
              />
            }
          />
          {!props.client && (
            <TableInputControlRow
              label={t("buffer")}
              controlInput={
                <Controller
                  name="buffer"
                  disabled={props.view || (!edit && params.id)}
                  control={control}
                  className={`form-control border-0 bg-light ${
                    !edit ? "shadow-none" : ""
                  }`}
                  autoComplete="off"
                  as={
                    <NumberFormat
                      suffix={" %"}
                      defaultValue="150"
                      placeholder={t("Enter buffer for Contract.")}
                    />
                  }
                  inputMode="numeric"
                  onChangeName="onValueChange"
                  onChange={([{ value }]) => value}
                />
              }
            />
          )}
          {!props.client && (
            <TableInputControlRow
              label={t("buffer2")}
              controlInput={
                <Controller
                  name="buffer2"
                  disabled={props.view || (!edit && params.id)}
                  control={control}
                  className={`form-control border-0 bg-light ${
                    !edit ? "shadow-none" : ""
                  }`}
                  autoComplete="off"
                  as={
                    <NumberFormat
                      suffix={" %"}
                      defaultValue="150"
                      placeholder={t("Enter buffer2")}
                    />
                  }
                  inputMode="numeric"
                  onChangeName="onValueChange"
                  onChange={([{ value }]) => value}
                />
              }
            />
          )}

          <TableInputControlRow
            label={t("monthlyMonthHourMax") + t("(Automatic calculation)")}
            controlInput={
              <span className="form-control">
                {getMonthlyManHourMax({
                  contractPeriodStart,
                  contractPeriodEnd,
                  contractManHour,
                  buffer,
                })}
                h
              </span>
            }
          />
        </tbody>
      </Table>
    </>
  );
}

export default ContractContent;
