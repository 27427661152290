const rules = {
  admin: [
    "view:clients",
    "list:clients",
    "update:clients",
    "delete:clients",
    "create:clients",
  ],
  client: ["view:clients"],
};

export default rules;
