import React from "react";

import Alert from "react-bootstrap/Alert";

const getErrors = (errors) => {
  return Object.keys(errors).map((k) => errors[k]["message"]);
};

export default function ({ errors }) {
  errors = getErrors(errors);

  if (!errors.length) {
    return null;
  }

  return (
    <Alert variant="danger">
      {errors.map((err, idx) => (
        <div key={idx}>{err}</div>
      ))}
    </Alert>
  );
}
