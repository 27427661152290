import React from "react";

import { getProgressStyle } from "../../../utils/helpers";

import "./index.css";

function ContractTimeProgress({ volume = 0, maxConsumed }) {
  return (
    <div className="d-flex mb-5 w-100">
      <div className="position-relative border border-black d-flex flex-grow-1 contract-progress-wrapper">
        <span
          className="h-100 contract-progress"
          style={getProgressStyle(volume, maxConsumed)}
        ></span>
        {/* ARROW IDENTIFIER /\ */}
        <span className="w-0 h-0 position-absolute contract-identifier-arrow"></span>

        {/* LINE IDENTIFIER | */}
        <span className="h-100 position-absolute contract-identifier-line"></span>
        <span
          className="h-100 position-absolute"
          style={{
            top: "-25px",
            left: "73.5%",
          }}
        >
          {maxConsumed}h
        </span>
      </div>

      <div className="my-auto mx-2 pl-4" style={{ width: "20%" }}>
        <div className="font-weight-bold">
          消費工数: {Number(volume || 0).toFixed(2)} h
        </div>
        <div className="font-weight-bold">
          残工数: {Number(maxConsumed - volume || 0).toFixed(2)} h
        </div>
      </div>
    </div>
  );
}

export default ContractTimeProgress;
