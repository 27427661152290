import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "ja",
    fallbackLng: "ja",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    resources: {
      ja: {
        translations: {
          title: "WillFind",
          id: "ID",
          welcome: "ようこそ!",
          inquiry: "お問い合わせ",

          password: "パスワード",
          username: "ユーザー名",
          yes: "はい ",
          no: "キャンセル",

          login: "ログイン",
          forgotYourIDOrPassword: "ID・パスワードをお忘れですか？",
          logout: "ログアウト",
          newClient: "クライアント新規登録",
          clientList: "クライアント一覧",
          code: "クライアントコード",
          client: "クライアント名",
          contractPeriod: "契約期間",
          contractManHours: "実績工数(h)",
          monthlyMaxEfforts: "月間最大工数",
          actualManHoursConsumed: "消化実工数累計",
          presentedManHoursConsumed: "肖化提示工数累計",
          basicInformation: "基本情報",
          edit: "編集",
          update: "更新",
          saving: "節約",
          register: "登録",
          updating: "更新中",
          clientCode: "クライアントコード",
          clientName: "クライアント名",
          loginID: "ログインID",
          confirmPassword: "パスワード確認",
          contractContent: "契約内容",
          contractPeriodStart: "契約期間（開始）",
          contractPeriodEnd: "契約期間（完了）",
          contractManHourWithinPeriod: "契約期間内工数",
          buffer: "契約月間最大工数用倍率(バッファ1)", // 契約上の上限
          buffer2: "実績工数 顧客提示用倍率(バッファ2)", // 月間
          monthlyMonthHourMax: "月間最大工数",
          actualManHourHistory: "実績工数履歴",
          totalActualManhourWithinContract: "契約期間内実績⼯数（集計）",
          remainingManhourWithinContract: "契約期間内残⼯数",
          userAlreadyExists: "は既に存在します。一意の値を入力してください。",
          digestionManHourEstimate: "消化工数（累計）",
          actualManHours: "実績工数(h)",
          presentationTimeXBuffer: "提示工数（ｘバッファ2）",
          clientPage: "クライアント ページ",
          period: "期間",
          send: "検索", // "送信",
          workHistory: "作業履歴",
          aggregationDate: "集計日",

          cancel: "キャンセル",

          "Please enter your user name (login ID)":
            "ユーザー名(ログインID)をご入力ください",
          "Please check your login ID and try again.":
            "ログインIDをご確認の上、再度お試しください",
          "Please enter your password": "パスワードをご入力ください",
          "Contract man-hour": "契約工数",
          "Presentation time": "提示工数",
          "(X buffer)": "（ｘバッファ）",
          display: "表示",
          "I could not login. Please check your user name (login ID) and password and try again.":
            "ログインできませんでした。ユーザー名(ログインID)とパスワードをご確認の上、再度お試しください。",
          "If you forget your login ID and password,":
            "ログインID、パスワードをお忘れの場合は、",
          "Contact your sales representative or":
            "営業担当者にお問い合わせ頂くか、",
          "Please contact us from the inquiry form.":
            "問い合わせフォームから御連絡下さい。",
          "Batch error log": "バッチエラーログ",
          "Enter client code": "クライアントコードを入力してください",
          "Please enter the client name": "クライアント名を入力してください",
          "Enter Client Login ID": "クライアントのログインIDを入力してください",
          "Enter Client Password":
            "クライアントのパスワード(半角英数8文字以上)",
          "Enter Client Confirm Password":
            "クライアント確認パスワードを入力してください",
          "The client update is complete.":
            "クライアントの更新が完了しました。",
          "Client addition is complete.": "クライアントの追加が完了しました。",
          "Cannot register because the user name is the same as the existing client. Please register with a different character string.":
            "ユーザー名が既存のクライアントと重複するため登録できません。別の文字列で登録してください。",
          "Unable to register because the client code duplicates the existing client. Please register with a different character string.":
            "クライアントコードが既存のクライアントと重複するため登録できません。別の文字列で登録してください。",
          "Enter contract period start": "契約期間開始を入力してください",
          "Enter contract period end": "契約期間終了日を入力してください",
          "Contract man-hour (h)": "契約工数(h)",
          "Enter contract effort": "契約工数を入力",
          "Enter buffer": "バッファを入力(％)",
          "(Automatic calculation)": "(自動計算)",
          "The client code is a required input item.":
            "クライアントコードは必須入力項目です。",
          "The client name is a required input item.":
            "クライアント名は必須入力項目です。",
          "Please check your user name and try again.":
            "ユーザー名をご確認の上、再度お試しください。",
          "Login ID (user name) is a required input item.":
            "ログインID(ユーザー名)は必須入力項目です。",
          "Password is a mandatory input school.":
            "パスワードは必須入力校億です。",
          "Password must at least 8 characters long and contains a special character (@-+.=_).":
            "半角英数記号を使い、8文字以上の文字列として作成してください。使用できる記号は、._-+=@ の6種です。",
          "The password confirmation field is mandatory.":
            "パスワードの確認フィールドは必須です。",
          "The confirmation passwords did not match.":
            "確認パスワードが一致しませんでした。",
          "A contract man-hour column is required.": "契約工数欄が必要です。",
          "The contract time must be a number.":
            "契約時間は数字でなければなりません。",
          "The contract period date format is incorrect. Please enter YYYYMMDD or YYYY-MM-DD.":
            "契約期間の日付のフォーマットが正しくありません。YYYYMMDDかYYYY-MM-DDで入力してください。",
          "Cannot be set because the end date of the contract period is specified as a date before the start date.":
            "契約期間の終了日が開始日より前の日付に指定されているため、設定できません。",
          "The contract period must end.": "期間終了日が必要です。",
          "Enter buffer for Contract.": "契約月間最大工数用倍率を正数値(%)で入力して下さい",
          "Enter buffer2": "実績工数 顧客提示用倍率を正数値(%)で入力して下さい",
          "The buffer must be numeric.": "バッファは数値でなければなりません。",
          "The buffer2 must be numeric.": "バッファは数値でなければなりません。",
          "The buffer field is mandatory.": "バッファフィールドは必須です。",
          "The period start date is required.": "期間の開始日が必要です。",
          "A monthly maximum effort field is required.":
            "毎月の最大工数フィールドが必要です。",
          "The actual man-hour csv download failed.":
            "実際の工数csvのダウンロードに失敗しました。",
          "Failed to download the work history csv.":
            "作業履歴csvのダウンロードに失敗しました。",
          "Client details": "クライアント詳細",
          "Client details admin": "クライアント詳細(管理)",
          "clientDetails": "クライアント詳細",
          "clientDetailsAdmin": "クライアント詳細(管理)",

          "Issue key": "課題キー",
          "Issue type": "種別",
          "Registration date": "登録日",
          "Update date": "更新日",
          "Start date": "開始日",
          Deadline: "期限日",
          "Estimated man-hours (h)": "予定工数(h)",
          "Actual man-hours (h)": "実績工数(h)",
          "Execution result of periodic data acquisition":
            "BacklogAPI:データ定期取得の実行結果(batch error logs)",
          Date: "日付",
          "Can I log out?": "ログアウトしてよいですか？",
          "Selected Date is invalid.":
            "契約期間の指定が正しくありません、日付を確認してください。",
          "Confirm Password is required.": "確認用パスワードは必須入力です。",
          "No Data": "対象となるデータが未だ見つかっていません",
          "Please enter a valid email address.":
            "ログインIDはメールアドレスの形式で入力して下さい。",
          "Generating work histories from backlog":
            "Backlogの課題データから集計中です…",
          Delete: "デリート",
          "Delete [item]": "本当に[item]を削除したいですか？",
        },
      },
    },
  });

export default i18n;
