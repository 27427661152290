import React from "react";
import { Switch, Route } from "react-router-dom";

import NotFoundPage from "../components/NotFoundPage";

import Login from "../views/public/login";
import ClientRegister from "../views/admin/clients/register";
import ClientList from "../views/admin/clients/list";
import ClientEdit from "../views/admin/clients/edit";
import Logs from "../views/admin/logs";

import ClientHome from "../views/client/home";

import PrivateRoute from "./PrivateRoute";
import * as ROUTE from "../constants/route";
import { AuthContext } from "../context/Auth";
import { isUserAdmin } from "../utils/helpers";

function Routes() {
  const { currentUser } = React.useContext(AuthContext);

  return (
    <Switch>
      {/* PUBLIC ROUTES */}
      <Route exact path={ROUTE.LOGIN} component={Login} />

      {/* AUTH ROUTES */}
      <PrivateRoute
        exact
        path={ROUTE.HOME}
        component={isUserAdmin(currentUser) ? ClientList : ClientHome}
      />
      <PrivateRoute exact path={ROUTE.CLIENTS} component={ClientList} />
      <PrivateRoute
        exact
        path={ROUTE.CLIENTS_REGISTER}
        component={ClientRegister}
      />
      <PrivateRoute exact path={ROUTE.CLIENTS_EDIT} component={ClientEdit} />
      <PrivateRoute exact path={ROUTE.CLIENTS_DETAILS} component={ClientHome} />
      <PrivateRoute exact path={ROUTE.LOGS} component={Logs} />

      {/*  */}
      <Route path={ROUTE.NOT_FOUND} component={NotFoundPage} />
    </Switch>
  );
}

export default Routes;
