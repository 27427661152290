import React from "react";

function FormHeading({ title, children }) {
  return (
    <h3 className="my-4 clients--basic-info__header d-flex justify-content-between">
      <span>{title}</span>
      <div className="w-50 d-flex justify-content-end">{children}</div>
    </h3>
  );
}

export default FormHeading;
