import Joi from "@hapi/joi";
import i18n from "../../../i18n";

export const validationSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": i18n.t("Please check your login ID and try again."),
      "string.empty": i18n.t("Please enter your user name (login ID)"),
      "any.required": i18n.t("Please enter your user name (login ID)"),
    }),
  password: Joi.string()
    .required()
    .messages({
      "string.empty": i18n.t("Please enter your password"),
      "any.required": i18n.t("Please enter your password"),
    }),
});

export const resolver = (data, validationContext) => {
  const { error, value: values } = validationSchema.validate(data, {
    abortEarly: false,
  });

  return {
    values: error ? {} : values,
    errors: error
      ? error.details.reduce((previous, currentError) => {
          return {
            ...previous,
            [currentError.path[0]]: currentError,
          };
        }, {})
      : {},
  };
};
