import React from "react";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ClientDeleteConfirmation({ onAction, show, client, ...props }) {
  const { t } = useTranslation();
  //
  const message = t("Delete [item]").replace("[item]", client?.code || "");

  return (
    <Modal
      {...props}
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p className="text-center mt-3">{message}</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button className="btn btn-danger" onClick={() => onAction("yes")}>
          {t("yes")}
        </Button>
        <Button onClick={() => onAction("no")}>{t("no")}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ClientDeleteConfirmation;
