import React from "react";

export const ClientFormContext = React.createContext();

export const ClientFormProvider = ({ onSubmit, children }) => {
  const handleFormSubmit = () => {
    onSubmit(true);
  };

  return (
    <ClientFormContext.Provider
      value={{
        handleFormSubmit,
      }}
    >
      {children}
    </ClientFormContext.Provider>
  );
};
