/**
 * 管理者側: 登録顧客一覧
 */
import React from "react";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";

import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import firebase from "../../../../services/firebase";
import { getDate, toPercentage, toDecimal } from "../../../../utils/helpers";
import CONSTANTS from "../../../../constants/common";

import usePagination from "../../../../hooks/usePagination";

import ClientDeleteConfirmation from "./ClientDeleteConfirmation";

import "./index.css";

export default function (props) {
  const [clients, setClients] = React.useState([]);
  const [pending, setPending] = React.useState(true);
  const [deleting, setDeleting] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(
    false
  );
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [sort, setSort] = React.useState({
    code: CONSTANTS.DESC,
    contractPeriodStart: CONSTANTS.DESC,
  });
  const { t } = useTranslation();
  const pagination = usePagination(clients);

  // Handlers
  const handleSort = (key) => {
    if (!sort[key]) {
      return;
    }

    const ascending = sort[key] === CONSTANTS.ASC;

    setClients(
      orderBy(clients, [`${key}`], [ascending ? CONSTANTS.DESC : CONSTANTS.ASC])
    );
    setSort({ ...sort, [key]: ascending ? CONSTANTS.DESC : CONSTANTS.ASC });
  };
  const handleDeleteClient = (c) => {
    setShowDeleteConfirmation(true);
    setSelectedClient(c);
  };
  const getClients = async () => {
    try {
      const clients = await firebase.clients().get();

      if (!clients.empty) {
        setPending(true);

        const data = clients.docs.map((d) => {
          const doc = { ...d.data() };

          return {
            ...doc,
            id: d.id,
            contractPeriodStart: getDate(doc.contractPeriodStart),
            contractPeriodEnd: getDate(doc.contractPeriodEnd),
            buffer: toPercentage(doc.buffer),
            buffer2: toPercentage(doc.buffer2),
            actualHours: toDecimal(doc.actualHours),
          };
        });

        setClients(orderBy(data, ["code"], [CONSTANTS.DESC]));
      }
    } catch (ex) {
    } finally {
      setPending(false);
    }
  };

  React.useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      <ClientDeleteConfirmation
        show={showDeleteConfirmation}
        client={selectedClient}
        onAction={async (e) => {
          setShowDeleteConfirmation(false);
          if (e === "yes") {
            try {
              setDeleting(true);
              await firebase.client(selectedClient.id).delete();
              setClients(clients.filter(({ id }) => id !== selectedClient.id));
            } catch (e) {
            } finally {
              setDeleting(false);
            }
          } else {
            setSelectedClient(null);
          }
        }}
      />
      <h1 className="text-center my-5">{t("clientList")}</h1>
      <Table striped bordered hover responsive>
        <thead>
          <tr className="bg-primary text-light text-center">
            <th
              rowSpan={2}
              style={{ verticalAlign: "middle" }}
              className="wf-hover"
              onClick={() => handleSort("code")}
            >
              {t("clientCode")}
            </th>
            <th rowSpan={2} style={{ verticalAlign: "middle" }}>
              {t("client")}
            </th>
            <th
              rowSpan={2}
              style={{ verticalAlign: "middle" }}
              className="wf-hover"
              onClick={() => handleSort("contractPeriodStart")}
            >
              {t("contractPeriod")}
            </th>
            <th rowSpan={2} style={{ verticalAlign: "middle" }}>
              {t("Contract man-hour")}
            </th>
            <th colSpan={2} style={{ verticalAlign: "middle" }}>
              {t("digestionManHourEstimate")}
            </th>
            <th rowSpan={2} style={{ verticalAlign: "middle" }}>
              {t("clientPage")}
            </th>
          </tr>
          <tr className="bg-info text-light text-center">
            <th style={{ verticalAlign: "middle", width: "11rem" }}>
              {t("contractManHours")}
            </th>
            <th style={{ verticalAlign: "middle", width: "11rem" }}>
              {t("Presentation time")}
              <br />
              {t("(X buffer)")}
            </th>
          </tr>
        </thead>
        <tbody>
          {pending && (
            <tr>
              <td colSpan={7} className="text-center">
                <Spinner size="sm" animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {!pending && !pagination.items.length && (
            <tr>
              <td colSpan={7} className="text-center">
                {t("No Data")}
              </td>
            </tr>
          )}
          {pagination.items.map((client, idx) => (
            <tr key={idx}>
              <td className="text-center">{client.code}</td>
              <td className="text-center">
                <Link to={`/clients/${client.id}`}>{client.name}</Link>
              </td>
              <td className="text-center">
                {moment(client.contractPeriodStart).format("YYYY/MM")} ～{" "}
                {moment(client.contractPeriodEnd).format("YYYY/MM")}
              </td>
              <td className="text-center">{client.contractManHour}</td>
              <td className="text-center">{client.actualHours}</td>
              <td className="text-center">{client.presentedManHour}</td>
              <td className="text-center">
                <Link
                  to={`/clients/${client.id}/details`}
                  className="btn btn-primary"
                >
                  {t("display")}
                </Link>
                {/* &nbsp;
                <Button
                  className="btn btn-danger"
                  onClick={() => handleDeleteClient(client)}
                  disabled={
                    deleting &&
                    selectedClient &&
                    selectedClient.id === client.id
                  }
                >
                  {deleting &&
                  selectedClient &&
                  selectedClient.id === client.id ? (
                    <Spinner size="sm" animation="border" variant="primary" />
                  ) : (
                    t("Delete")
                  )}
                </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <Pagination
          activePage={pagination.currentPage}
          itemsCountPerPage={pagination.perPage}
          totalItemsCount={pagination.total}
          onChange={(page) => pagination.setCurrentPage(page)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  );
}
