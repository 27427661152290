import React from "react";

import Form from "react-bootstrap/Form";

function FormControlFeedback({ error, type = "invalid" }) {
  if (error) {
    return (
      <Form.Control.Feedback type={type}>{error.message}</Form.Control.Feedback>
    );
  }
  return null;
}

export default FormControlFeedback;
